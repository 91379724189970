import { AppConfig } from "../constants/config";

export const addStyle = (styleString) => {
  const style = document.createElement("style");
  style.textContent = styleString;
  document.head.append(style);
};

export const appendStyle = function (content) {
  const style = document.createElement("STYLE");
  style.type = "text/css";
  style.appendChild(document.createTextNode(content));
  document.head.appendChild(style);
};

export const loadGoogleFonts = function (fontName) {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.media = "all";
  link.href = `https://fonts.googleapis.com/css?family=${fontName}`;
  document.head.appendChild(link);
};

export const addScript = (srcScript = "") => {
  const script = document.createElement("script");
  let strScript = srcScript;
  while (strScript.indexOf("u0021") >= 0) {
    strScript = strScript.replace("u0021", "!");
  }
  script.innerHTML = strScript;
  document.body.appendChild(script);
};

export const getPageJson = (configs, name) => {
  return configs.filter((c) => c.name === name)[0].content;
};

export const showLoading = () => {
  $("#loading").show();
  $("html, body").css({
    overflow: "hidden",
    height: "100%",
  });
};

export const hideLoading = () => {
  $("#loading").hide();
  $("html, body").css({
    overflow: "auto",
    height: "auto",
  });
};

// Workaround for demo only
export const tempRouting = (uuid) => {
  document.addEventListener("click", (e) => {
    const aTag = e.target.closest("a");
    if (
      aTag &&
      aTag.attributes.href &&
      (aTag.attributes.href.value === "#" || !aTag.attributes.href.value)
    ) {
      return e.preventDefault();
    }
    if (aTag && aTag.attributes.href && !aTag.attributes.target) {
      e.preventDefault();
      const href =
        aTag.attributes.href.nodeValue === "/"
          ? "/index"
          : aTag.attributes.href.nodeValue;
      const urlFormatted = href.split("?");
      const urlTmpl = `${urlFormatted[0]}.html${
        urlFormatted[1] ? "?" + urlFormatted[1] : ""
      }`;
      window.location = urlTmpl;
    }
  });
};

export const formatUrl = (href) => {
  const uuid = localStorage.getItem("template_uuid");
  const path = href === "/" ? "/index" : href;
  const urlFormatted = path.split("?");
  let search = "";
  urlFormatted.forEach((url, i) => {
    if (i !== 0) {
      search += `${url}${i !== urlFormatted.length - 1 ? "?" : ""}`;
    }
  });
  return `${urlFormatted[0]}.html${search ? `?${search}` : ""}`;
};

export const showAlert = (msg) => {
  if (!msg) {
    return;
  }
  $("#notification").fadeIn("slow");
  $("#notification > .toast-content").text(msg);
  $(".dismiss").click(function () {
    $("#notification").fadeOut("slow");
  });
  setTimeout(() => {
    $("#notification").fadeOut("slow");
  }, 5000);
};
